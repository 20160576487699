.user-modal {
    margin-top: 10vh;
}

.etl-detail-modal .modal-content {
    margin-top: 10vh;
    margin-left: -45vh;
    width: 80vw !important;
    height: 70vh;
}

.log-result {
    width: 100%;
    height: 40vh;
    border: 1px solid #F0F0F0;
    font-size: 11px;
}

.history-table-custom {
    height: '100vh' ;
    max-width: 100%;
}

.etl-detail-modal .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    height: 70%;
}

.etl-detail-modal .container {
    height: 80%;
    overflow: auto;
}

.etl-detail-info {
    display: flex;    
    align-items: baseline;
    padding: 0;
}

.etl-detail-info p {
    width: 70%;
    display: inline-block;
    font-weight: bold;
    font-size: 1.6em;
    color: #8d8d8d;
}

.etl-detail-info button {
    width: 30%;
    display: inline-block;
    font-size: 12px;
    margin-top: 0;
}

.load-block {
    display: flex;
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    z-index: 9;
    left: 0;
    justify-content: center;
    align-items: center;
    color: #F0F0F0;
    font-weight: bold;
    font-size: 2em;
    top: 0;
}

.tabs-company {
    margin-top: 10vh;
}

.tab-content-company {
    margin-top: 3vh;
}