.app-sidebar {
  z-index: 1 !important;
}

.app-sidebar--header {
  justify-content: center !important;
  padding: 0 !important;    
}

.white {
  color: #FFFFFF !important;
}

.app-header--pane::after {
  background: rgba(255, 255, 255, 0.15) !important;
  content: "";
  width: 110%;
  left: 0;
  bottom: 0;
  height: 1px;
  position: relative;
  display: block;
  margin-top: 10px;
  margin-left: -20px;
}

.userIcon {
  font-size: 1.8em;
  margin-right: 10px;
}

.app-header--pane {
  width: 100%;
  display: block;
  padding-left: 20px;
}

.flagMenu {
  background-color: #122e4d;
  cursor: pointer;
  padding: 15px;
  font-size: 24px;
  color: #FFFFFF;
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 5px;
  margin: 0;
  display: block;
}

.menuCollapsed {
  width: 70px;
  display: block;
  overflow: hidden;
  position: relative;
  transition: width 0.4s ease;
}

.app-sidebar {
  position: relative !important;
}

.menuCollapsed .app-sidebar {    
  width: 70px;    
}

.menuCollapsed .sidebar-header, .menuCollapsed .sidebar-item-label, .menuCollapsed .app-header--pane {
  display: none;
}


.app-header-fixed .app-content {
  padding: 0 !important;
}


@media (max-width: 1099.98px) {
  .app-sidebar {
      position: fixed !important;
      left: 0px !important;
  }
  .menuCollapsed .app-sidebar {    
      width: 0px;
  }
}