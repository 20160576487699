@import url(https://fonts.googleapis.com/css?family=Open+Sans);
.telaprincipal{
    width: 100%;
    height: 100%;
    display: flex;
}

.telalogin{
    background-color:#fff;
    width: 35vw;
    height: 100vh;
    background-position: top,left;
}

.logopequeno{
    width: 100%;
    height:auto;
    background-repeat: no-repeat;
    display: flex;
    margin-left: 15%;
    margin-top: 5%;
}

.caixalogin{
    width: 100%;
    height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-custom{
    width: 130%;
    height: 50px;
    margin-left: -15%;
    border: 1px solid #889393;
    box-sizing: border-box;
    border-radius: 8px;
}

.input-custom .form-control{
    border: 1px solid #fff;
    height: 100%;
    border-radius: 8px;
}

.buttonentrar{
    width: 130%;
    margin-left: -15%;
    box-sizing: border-box;
    background-color: #122e4d;
    border-color: #122e4d;
    color: #fff;
    height: 50px;
    border-radius: 8px;
    margin-top: 5%;
    pointer-events:all;
}

.buttonentrar:hover, .buttonentrar:focus, .buttonentrar:active {
    box-shadow: none !important;
    background-color: #353FAC !important;
    border-color: #353FAC !important;
    transform: none !important;
}
.label-login {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #2B3636;
    margin-bottom: 0;
    margin-left: -12%;
}

.less-margin{
    margin-left: 0;
}

.text-senha {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    width: 52%;
    text-align: right;
    color: #353FAC;
}

.text-cancel {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    width: 100%;
    text-align: center;
    color: #353FAC;
    margin-top: 5vh;
    display: block;
}

.error-confirmation {
    color: #FF0000;
    margin-top: 5px;
    display: block;
    font-size: 0.8em;
}

.text-senha:hover, .text-senha:active{
    box-shadow: none !important;
    transform: none !important;
    touch-action: none !important;
    transition-property: none !important;
    text-decoration: none !important;
    text-transform: none !important;
}

.forgot-div{
    justify-content: space-between;
    width: 130%;
    display: flex;
    margin-left: -15%;
}

.text-rodape{
    height: 9vh;
    width:100%;
    margin-top: 8%;
    font-family: Open Sans;
    font-size: 12px;
    color: #879696;
    text-align: center;
    /* margin-left: -12%; */
    /* justify-content: end; */
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.mainscreen{
    background-image: url(../../../assets/images/Group3.png);    
    background-size: cover;
    background-position:top,right;
    background-repeat: no-repeat;
    width: 65vw;
    height: 100vh;
}

@media only screen and (max-width: 900px) {
    .mainscreen {
      display: none;
    }
    .caixalogin {
        width: 100vw;
    }
  }

  @media only screen and (min-width: 1450px) {
    .logopequeno{
      width: 200%; 
      text-align: center;  
    margin-left: 25%;  }
 }
 @media only screen and (max-width: 450px) {
    .text-rodape{
        margin-left: 10%;
        width: 259%;
        
    }
 }

  

