@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;500;900&display=swap');


li {
    list-style: none !important;
}

.btn-main-theme {
    background-color: #373DBA !important;
    opacity: 0.95;
}

.icon-list-main {
    font-size: 20px;
    color: #CECECE;
    cursor: pointer;
}

.alert {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99999;
}

.doBktq {
    cursor: pointer;
}